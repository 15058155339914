<template>
<div>
    <Loading  v-if="!requested" />
    <div v-else>
    <van-overlay :show="show" />
        <div class="main">
            <div class="nav">
                <div class="nav-main">
                    <div class="left-logo">
                        <a href="/" :class="{ 'logo-link': overlay}" >
                            <img :src="HomeLogo" alt="fastorder_logo" />
                        </a>
                        <van-overlay z-index="0" :show="overlay" @click="overlay = false" />
                        <SearchBar @deactivateOverlay="disableOverlay" @activateOverlay="setOverlay" :hideLogo="hideLogo" :overlay="overlay" />
                    </div>
                    
                    <div class="right-logo">
                        <div class="link">
                            <a href="/account">Join Us</a>
                        </div>
                        <div class="divider"></div>
                        <div class="link">
                            <a href="/account">Sign In</a>
                        </div>
                    </div>
                </div>
            </div>
            <div class="result">
                <div class="content">
                    <div class="filter">
                        <h1>Showing result for "{{filter}}"</h1>
                    </div>

                    <div class="result-section" v-if="items.length>0">
                        <div class="restaurant-section">
                            <div class="type">Restaurants</div>
                            <div v-if="restaurants.length>0" class="restaurant-main">
                                <div v-for="(item,index) in restaurants.slice(0,showItem)" :key="index">
                                    <div class="restaurant-card" @click="clickStore(item)">
                                        <div>
                                            <v-card width="90">
                                                <v-img 
                                                :src="item.store.thumbnail"
                                                height="90">
                                                </v-img>
                                            </v-card>
                                        </div>
                                        
                                        <div class="detail">
                                            <div class="title">{{item.store.name}}</div>
                                            <div class="feats">
                                                <div class="feat" v-for="feature in item.features" :key="feature.id">{{feature}},</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div v-else class="mb-4">
                                No result
                            </div>

                            <div class="more" v-if="showItem < restaurants.length" @click="showItem += 2">More Restaurants</div>
                            <div class="more" v-if="showItem >= restaurants.length &&page<lastPage" @click="page +=1">Load More Results</div>

                        </div>
                        <div class="cuisine-section">
                            <div class="type">Cuisines</div>

                            <div v-if="cuisines.length>0" class="cuisine-main">
                                <div v-for="(item,index) in cuisines.slice(0,showCuisine)" :key="index" class="cuisine-product">
                                    <div class="cuisine-card" @click="clickStore(item)">
                                        <div>
                                            <v-card width="120">
                                                <v-img 
                                                :src="item.store.thumbnail"
                                                height="110">
                                                </v-img>
                                            </v-card>
                                        </div>
                                        
                                        <div class="cuisine-info">
                                            <div class="cuisine-name">{{item.store.name}}</div>
                                            <div class="desc">
                                                <div class="feat" v-for="category in item.store.categories" :key="category.id">
                                                    {{category}}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="btn-show-cuisine">
                                        <button style="margin-bottom:2rem" v-if="showItem < cuisines.length" @click="showCuisine+= 2">Load More..</button>
                                    </div>
                            </div>
                            <div v-else class="mb-4">
                                No result.
                            </div>
                            <div class="more btn-load-cuisine" v-if="showItem < cuisines.length" @click="showCuisine+= 2">More Items</div>
                        </div>
                        
                        <div class="food-section">
                            <div class="type">Items</div>
                            <div class="food-main">
                                <div v-if="dishes.length>0">
                                <div class="food-product isdesktop">
                                    <div v-for="food in dishes.slice(0,showFood)" :key="food.id" class="food-card" @click="clickFood(food)">
                                        <div>
                                            <v-card width="150">
                                            <v-img 
                                            :src="food.thumbnail"
                                            height="120">
                                            </v-img>
                                        </v-card>
                                        </div>
                                        
                                        <div>
                                            <div class="food-name">{{food.name}}</div>
                                            <div class="food-category" v-for="category in food.categories" :key="category.id">
                                                <div class="feat">
                                                    {{category}} {{food.id}}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    
                                    <div style="display:grid;place-items:start">
                                        <button style="margin-bottom:2rem" v-if="showFood < dishes.length" @click="showFood+= 3">
                                            <v-card width="120" height="120">
                                            <v-img 
                                            >
                                            <v-card-subtitle class="py-11">Expand</v-card-subtitle>
                                            </v-img>
                                        </v-card>
                                        </button>
                                    </div>
                                </div>

                                <div class="food-product ismobile">
                                    <div v-for="food in dishes" :key="food.id" class="food-card" @click="clickFood(food)">
                                        <div>
                                            <v-card width="150">
                                            <v-img 
                                            :src="food.thumbnail"
                                            height="120">
                                            </v-img>
                                        </v-card>
                                        </div>
                                        
                                        <div>
                                            <div class="food-name">{{food.name}}</div>
                                            <div class="food-category" v-for="category in food.categories" :key="category.id">
                                                <div class="feat">
                                                    {{category}} {{food.id}}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="more" v-if="page<lastPage" @click="page +=1">Load More Results</div>
                                </div>
                                <div v-else>
                                    No result
                                </div>
                            </div>
                        </div>

                        <div class="address-section">
                            <div class="type">Destination</div>

                            <div class="addr-main" v-if="restaurants.length>0">
                                <div class="addr-card" v-for="restaurant in restaurants.slice(0,showAddress)" :key="restaurant.id">
                                    <div class="addr-icon" >
                                        <v-icon style="padding:0.4rem">{{icons.mdiMapMarker}}</v-icon>
                                    </div>
                                    <div class="addr-text">
                                        <!-- <div class="addr-large">{{restaurant.city}}, {{restaurant.state}}</div> -->
                                        <div class="addr-small">{{restaurant.store.address}}</div>
                                    </div>
                                </div>
                            </div>
                            <div v-else>
                                No result
                            </div>
                            <div class="more" v-if="showAddress < restaurants.length" @click="showAddress+= 1">More address</div>
                        </div>
                    </div>
                    <div v-else>
                        <div style="margin-left:14px;font-size:1.4rem;font-style:italic">No result</div>
                    </div>

                </div>
            </div>
        </div>
        
    </div>
    <NavFooter />
    </div>
</template>
<script>
import HomeLogo from '@/assets/logo2.png'
import Loading from "../view/Loading.vue"
import SearchBar from '@/components/common/SearchBar.vue'
import { getStoreCategory} from '@/components/api/api'
import NavFooter from '../components/common/NavFooter.vue'
import { mdiMapMarker } from '@mdi/js';

export default {
    name: "Search",
    components: {
        SearchBar,
        Loading,
        NavFooter
    },
    data(){
        return {
            show:false,
            icons:{
                mdiMapMarker
            },
            numhitsPerPage: 20,
            numberOfHits:0,
            page: 1,
            totalPages:0,
            lastPage:1,

            cuisines:[],
            items: [],
            dishes: [],
            index : null,
            query:'',
            HomeLogo,
            hideLogo:true,
            overlay:false,
            requested:false,
            filter: "",
            results:[],
            restaurants: [],
            showItem: 4,
            showCuisine:4,
            showProducts:1,
            showFood:10,
            showAddress:4,
            totalRestaurants: 0
        }
    },
    mounted(){
        this.totalRestaurants = this.restaurants.length
    },
    created() {
        this.index = this.$searchClient.initIndex(this.$clientIndex)
        this.getData()
    },
    watch: {
        "$route.query.q":function(){    
            this.getData()

            console.log('sss')
        },
        page: function(){
            this.getData()
        }
    },
    methods :{
        clickFood(item){
            const itemId =item.id
            const storeId = item.store.id
            const origin =  "https://merchant.fastorder.my"
            const pathname= "/v202105/"
            const hash = `#/?i=2&c=entry&do=waplist&m=weisrc_dish&storeid=${storeId}&mode=2&append=1&from_user=&lang=en&itemid=${itemId}`
            const url = origin+pathname+hash
            window.location= url
        },
        clickStore(item){
            const storeId = item.store.id
            const origin =  "https://merchant.fastorder.my"
            const pathname= "/v202105/"
            const hash = `#/?i=2&c=entry&do=waplist&m=weisrc_dish&storeid=${storeId}&mode=2&append=1&from_user=&lang=en&tabletype=delivery`
            const url = origin+pathname+hash
            window.location= url
        },
        getData(){
            try {
                this.query = this.$route.query.q
                this.filter = this.query
                this. filterResult()
                this.requested = true
            } catch (error) {
                console.log('Error! :',error)
            }
        },
        async filterResult(){
            const result = await this.index.search(this.query,{
                // page: 1,
                page: this.page-1,
                hitsPerPage: this.numhitsPerPage
            });
            this.numberOfHits = result.nbHits
            this.totalPages = result.nbPages
            this.lastPage = result.nbPages

            // this.items.push(...result.hits)
            this.items = result.hits
            console.log('res=>',result)
            console.log('hit',result.hits)
            console.log('total hits', this.items)

            this.filterRestaurant(this.items)
            this.filterDishes(this.items)
            this.filterCuisine(this.items)
        },
        filterUnique(products){
            let x = products.reduce((acc, current) => {
                const x = acc.find(item => item.id == current.id);
                if (!x) {
                    return acc.concat([current]);
                } else {
                    return acc;
                }
            }, []);
            return x
        },

        filterRestaurant(hits){
            let uniqueStore = hits.reduce((acc, current) => {
                const x = acc.find(item => item.store.id == current.store.id);
                if (!x) {
                    return acc.concat([current]);
                } else {
                    return acc;
                }
            }, []);
            console.log('filter store', uniqueStore.length)
            this.restaurants= uniqueStore
        
        },
        filterDishes(hits){
            let products = []
            hits.forEach(item =>{
                products.push(item)
            })
            let uniqueDishes = this.filterUnique(products)
            this.dishes = uniqueDishes
            // console.log('filter items', uniqueDishes)
        },

        filterCuisine(hits){ 
            let cuisines = []
            hits.map(item =>{
                item._highlightResult.store.categories.map(category=>{
                    if(category.fullyHighlighted == undefined) return
                    cuisines.push(item)
                })
            })
            let uniqueCuisines = cuisines.reduce((acc, current) => {
                const x = acc.find(item => item.store.id == current.store.id);
                if (!x) {
                    return acc.concat([current]);
                } else {
                    return acc;
                }
            }, []);
            this.cuisines = uniqueCuisines
        },

        setOverlay(){
            this.overlay= true;
        },
        disableOverlay(){
            this.overlay=false
        }
    }
}
</script>
<style lang="scss" scoped>
@import "../components/common/css/global.scss";
@import "../components/common/css/common.scss";

.nav{
    position: sticky;
    top: 0;
    background: $white;
    z-index: 4;
    display: block;
    padding: 0 40px;
    border-bottom: 1px solid #e2e2e2;
    @media screen and (max-width: $bp-mobile){
        padding: 0;
    }
}
.nav-main{
    margin: 0 auto;
    max-width: 1600px;
    height: 80px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    @media screen and (max-width: $bp-mobile){
        height: 60px;
    }
}

.left-logo{
    display: flex;
    align-items: center;
    width: 100%;
    @media screen and (max-width: $bp-mobile){
        padding: 0 10px;
    }

    .logo-link{
        @media screen and (max-width: $bp-mobile){
            display: none;
        }
    }

    img{
        margin-right: .5rem;
        display: block;
        height: 40px;
        @media screen and (max-width: $bp-mobile){
            height:30px;
        }
    }
}
.right-logo{
    display: flex;
    align-items: center;
    margin-left: 1rem;

    @media screen and (max-width: $bp-mobile){
        display: none;
    }
}
.divider{
    width: 2px;
    height: 1rem;
    background: $bgColor2;
    margin: 0 1.1rem;
}
a{
    white-space: nowrap;
    color: $red1;
    font-size: 1.2rem;
    font-weight: 600;
}

.result{
    padding: 0 40px 80px;
    @media screen and (max-width: $bp-mobile){
        padding: 0 0 80px 0;
    }
}

.content{
    max-width: 1600px;
    margin: 0 auto;
}
h1{
    margin: 2px 0 10px;
    font-family: roboto;
    font-size: 1.8rem;
    color: $red1;
    @media screen and (max-width: $bp-mobile){
        font-size: 1.2rem;
    }
}
.text-filter{
    padding: 0 3px;
    background: rgba(0, 0, 0, 0.05);
    
}
.filter{
    padding: 14px;
}

.related-result{
    display: flex;
    flex-wrap: wrap;
    padding: .4rem 0;
    justify-content: flex-start;
}
.box{
    display: flex;
    background: rgba(0, 0, 0, 0.2);
    padding: .2rem .5rem;
    margin:0.2rem .5rem;
}
.box:nth-child(1){
}
::v-deep .text-result > em{
    background: transparent !important;
    font-style: normal !important;
    font-family: Arial, Helvetica, sans-serif !important;
}

.restaurant-main{
    display: flex;
    padding: 0.8rem 0 2rem 0rem;
    flex-wrap: wrap;

    @media screen and (max-width: $bp-mobile){
        display: flex;
        flex-direction: column;
        padding: 0;
    }
}
.restaurant-card{
    cursor: pointer;
    padding: .8rem;
    height: 7rem;
    width: 20rem;
    box-shadow: 2px 2px 4px 0 #bdbbbb;
    display: flex;
    margin-right: 1rem;
    margin-bottom: 1rem;
    border-bottom: none !important;
    @media screen and (max-width: $bp-mobile){
        border: none !important;
        box-shadow: none;
        padding: .4rem 0;
        margin-bottom:0 ;
    }
}
.type{
    font-size: 1.4rem;
    font-weight: 600;
    margin-bottom: .4rem;
    @media screen and (max-width: $bp-mobile){
        font-size: 1.2rem;
    }
}
.detail{
    margin-left: .6rem;
}
.title{
    display: block; 
    font-size: 0.9rem !important;
    color: $gray2;
    line-height: normal;
    margin-bottom: 0.4rem;
    max-height: 34px;
    overflow: hidden;
}
.desc{
    font-size: 0.8rem !important;
    color: $gray4;
    font-weight: 500;
    margin-bottom: 0.1rem;  
}
.feats{
    font-size: 0.7rem !important;
    color: $gray4 !important;
    display: flex;
}
.feat{
    margin-right: 0.3rem;
}

.more{
    text-align: center;
    padding: 0.6rem;
    border-top: 2px solid $white5;
    font-size: 0.9rem;
    font-weight: 500;
    color: $red1;
    cursor: pointer;
}

.restaurant-section{
    padding: 0.8rem 0.9rem 0;
}

.cuisine-section{
    border-top: 8px solid $white5;
    padding: 0.8rem 0.9rem 0;
}
.cuisine-main{
    display: flex;
    overflow:visible;
    flex-wrap: wrap;
    padding: 0.8rem 0 1rem;
    @media screen and (max-width: $bp-mobile){
        flex-wrap: nowrap;
        overflow:scroll;
    }
    
}
.cuisine-product{
    padding: 0.8rem 0 1rem 0.3rem;  
}
.cuisine-card{
    cursor: pointer;
    margin-right: 1rem;
    display: flex;
    flex-direction: column;
    width: 120px;
}
.cuisine-info{
    margin-top: .6rem;
}
.cuisine-name{
    line-height: normal;
    font-size: 0.9rem;
    max-height:42px;
    overflow: hidden;
}
.btn-show-cuisine{
    display: none;
    @media screen and (max-width: $bp-mobile){
        display:grid;
        place-items:center
    }
}
.btn-load-cuisine{
    display: block;
    @media screen and (max-width: $bp-mobile){
        display: none;
    }
}

.food-section{
    border-top: 8px solid $white5;
    padding: 0.8rem 0.9rem 0;
}
.food-main{
    padding: 0.8rem 0 1rem;
    @media screen and (max-width: $bp-mobile){
        padding: 0 0 1rem;
    }
}
.isdesktop{
    display: flex;
    @media screen and (max-width: $bp-mobile){
        display: none;
    }
}
.ismobile{
    display: none;
    @media screen and (max-width: $bp-mobile){
        display: flex;
    }
}
.food-product{
    /* display: flex; */
    overflow: visible;
    flex-wrap: wrap;
    padding: 0.8rem 0 0.4rem 0.3rem;

    @media screen and (max-width: $bp-mobile){
        overflow: scroll;
        flex-wrap: nowrap;
        padding: 0.3rem 0 0.4rem;
    }
}
.food-card{
    cursor: pointer;
    display: flex;
    flex-direction: column;
    width: 120px;
    margin-right: 1rem;
    margin-bottom: 1rem;
}
.food-name{
    margin-top: 0.6rem;
    font-size: 0.9rem;
    color: $gray2;
    line-height: normal;
    max-height: 42px;
    overflow: hidden;
}
.food-category{
    margin-top: 0.4rem;
    font-size: 0.7rem;
    color: $gray5;
    line-height: 1rem;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

.address-section{
    border-top: 8px solid $white5;
    padding: 0.8rem 0.9rem 0;
}
.addr-card{
    padding: 0.4rem 0;
    display: flex;
    align-items: center;
    cursor: pointer;
}
.addr-icon{
    border-radius: 50%;
    display: grid;
    place-items: center;
    box-shadow: 0 0 2px 0 $gray4;
}
.addr-text{
    margin-left: 0.8rem;
    width: 100%;
}
.addr-large{
    max-height: 3rem;
    overflow: hidden;
}
.addr-small{
    font-size: .8rem;
    color: $gray5;
    max-height: 2.4rem;
    overflow: hidden;
}


</style>